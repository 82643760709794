import(/* webpackMode: "eager", webpackExports: ["PostHogPageView"] */ "/vercel/path0/app/page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/vercel/path0/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.7_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.7_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["MainNav"] */ "/vercel/path0/src/components/main-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StickyHeader"] */ "/vercel/path0/src/components/ui/sticky-header.tsx");
